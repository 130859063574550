// Config values
$platform-name: "L'Arche";

// Colours
$brand-primary: #FF7800;
$brand-secondary: #008029;
$brand-tertiary: #FF7080;
$brand-quarternary: #334DB5;

$bg-dark: #1C1269;
$bg-light: #334DB5;
$bg-color: #FFFFFF;
$bg-grey: #F4F3F8;

$font-colour: #0C0C0C;
$body-colour: #1C1269;
$border-colour: #ADADAD;

// Links
$link-colour: #334DB5;

// Greyscale
$black: #000;
$white: #fff;

// Logo
$logo-width: 185px;
$logo-height: 56px;
$logo-mobile-width: 132px;
$logo-mobile-height: 39.85px;

// Typography
$font-family-base: "rucksack", sans-serif;
$font-family-sign: "ccsignlanguage", cursive;

// Font sizes
$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 14px;

$font-size-h1: 49px;
$font-size-h2: 39px;
$font-size-h3: 31px;
$font-size-h4: 25px;
$font-size-h5: 20px;
$font-size-h6: 16px;

$font-mobile-h1: 29px;
$font-mobile-h2: 26px;
$font-mobile-h3: 23px;
$font-mobile-h4: 20px;

$font-display-1: 99px;
$font-display-2: 82px;
$font-display-3: 69px;
$font-display-4: 57px;

// Font weights
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;
$line-height-base: 1.625;

// Headings
$headings-font-weight: $font-weight-black;
$headings-line-height: 1.4;
$headings-colour: #1C1269;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$container-max-width: 1600px;
$container-grid-size: 85%;
$site-bleed: 0px;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: $font-colour;
$btn-border-radius: 10px;
$btn-padding-x: 27px;
$btn-padding-y: 18px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 1.25;

// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-max-width: 220px;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 21.5px 3.5px;
$header-search-input-placeholder-colour: $white;
$header-search-input-border-radius: 0;
$header-search-input-border-colour: #ADADAD;
$header-search-input-border-width: 0px 0px 1px 0px; // t-r-b-l
$header-search-input-background-colour: transparent;
$header-search-input-colour: $white;
$header-search-input-focus-background-colour: transparent;
$header-search-input-focus-colour: $white;

// Search - button
$header-search-button-width: 12px;
$header-search-button-height: 15px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 12px;
$header-search-button-icon-colour: $white;
$header-search-button-border-radius: 0;

// Menu admin
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-background-colour: $bg-dark;
$menu-admin-link-colour: $white;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 15px;
$header-content-padding-bottom: 15px;

// Navigation
$nav-background-colour: transparent;

// Top level items
$nav-top-level-item-padding: 0;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-size: $font-size-large;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-background-colour: transparent;

// Submenus
$nav-submenu-width: 270px;
$nav-submenu-background-colour: $bg-light;
$nav-submenu-item-padding: 15px 20px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-normal;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $brand-secondary;

// Nav normal
$nav-normal-mobile-background-colour: $bg-dark;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: rgba($white, 0.08);

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-max-width: $container-grid-size;
$carousel-details-padding: 0;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-colour: $white;
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-background-colour: $brand-primary;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 50px;
$carousel-controls-button-gap: 11.75px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: transparent;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: left; // split, left or right
$carousel-controls-icon-colour: transparent;
$carousel-controls-next-icon: "\f0da"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f0d9"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Home intro
$home-intro-background-colour: transparent;
$home-intro-text-align: left;
$home-intro-padding-y: 0;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 60px;
$home-intro-font-size: $font-size-h2;
$home-intro-mobile-font-size: $home-intro-font-size;

// Home features
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-max-width: 100%;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 100px;

// Feeds title
$feeds-title-colour: $headings-colour;
$feeds-title-text-align: center; // left, center, right
$feeds-title-margin-bottom: 60px;

// Card
$card-border: none;
$card-image-background-colour: transparent;
$card-details-padding: 30px;
$card-details-background-colour: $bg-grey;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: 10px;
$card-heading-colour: $bg-dark;
$card-summary-enabled: true;
$card-summary-colour: $bg-dark;

// Card hover state
$card-hover-heading-colour: $white;
$card-hover-summary-colour: $white;
$card-hover-image-opacity: 1;
$card-hover-details-background-colour: $bg-light;

// Footer
$footer-padding-y: 0;
$footer-background-colour: transparent;
$footer-colour: $white;
$footer-link-decoration: none;
$footer-link-hover-decoration: none;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Footer specific social icons
$social-icons-background-colour: $brand-primary; // A colour, transparent, or 'brand'
$social-icons-colour: $black; // A colour, or 'brand'
$social-icons-hover-colour: $black; // A colour, or 'brand'
$social-icons-width: 32px;
$social-icons-height: 32px;
$social-icons-font-size: $font-size-base;
$social-icons-border-radius: 50%;
$social-icons-gap: 10px;

// Newsletter
$newsletter-background-colour: $bg-light;
$newsletter-colour: $white;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-heading-margin-bottom: 30px;
$newsletter-button-height: 50px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: $font-colour;
$newsletter-button-border-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: 60px;
$newsletter-hero-contents-max-width: 100%;

// Quick giving panel
$quick-giving-background-colour: transparent;
$quick-giving-padding: 0;
$quick-giving-donation-amount-figure-font-size: $font-size-h4;
$quick-giving-donation-amount-padding: 28px 26px;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: $font-colour;
$quick-giving-donation-amount-selected-background-colour: $brand-tertiary;
$quick-giving-donation-amount-selected-figure-colour: $quick-giving-donation-amount-figure-colour;

// Impact stats
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: 80px;
$impact-stats-background-colour: $bg-dark;
$impact-stats-border-radius: 0;
$impact-stats-text-align: left; // left, center, right

// Heading
$impact-stats-heading-colour: $white;
$impact-stats-heading-margin-bottom: 60px;

// Individual stat
$impact-stat-min-width: auto; // Use this to control how many you get in a row
$impact-stat-margin: 0;

// Figure
$impact-stats-figure-colour: $white;
$impact-stats-figure-font-size: $font-display-4;

// Summary
$impact-stats-summary-colour: $white;
$impact-stats-summary-font-size: $font-size-base;

// Slick
$slick-enabled: true;

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-adjacent-gap: 60px; // distance between sidebar and post content
$sidebar-border-radius: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h2;
$associated-item-image-enabled: true;
$associated-item-heading-font-size: $font-size-h4;
$associated-item-heading-colour: $headings-colour;
$associated-item-summary-enabled: true;
$associated-item-border: false;

// Blockquote
$blockquote-background-colour: $bg-light;
$blockquote-border-radius: 20px;
$blockquote-padding: 40px 30px 35px;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-large; // Em unit will cascade to children
$blockquote-colour: $white;
